import React from 'react'
import SEO from '../components/seo'

import Text from '../components/default/TextPage'
import Wrapper from '../components/styled/Wrapper'
import MaxWidth from '../components/styled/MaxWidth'

const Project = props => {
  const data = props.pageContext.data

  return (
    <div>
      <Wrapper borderB>
        <SEO title={data.seoTitle} description={data.seoDescription} />

        <MaxWidth toLeft>
          <Text data={data} />
        </MaxWidth>
      </Wrapper>
    </div>
  )
}

export default Project
