import React from 'react'
import styled from 'styled-components'

import Wysiwyg from '../styled/Wysiwyg';

const Heading = styled.div`
  font-size: 26px;
  letter-spacing: 0;
  line-height: 34px;
  padding-top: 133px;
  margin-bottom: 78px;
 
  @media only screen and (min-width: 1100px){
    font-size: 38px;
    line-height: 48px;
  }
`
const TextPage = ({ data }) => {
  return (
    <section>
      <Heading>
        {data.title}
      </Heading>
      <Wysiwyg
       dangerouslySetInnerHTML={{ __html: data.content.html }}>
      </Wysiwyg>
    </section>
  )
}

export default TextPage
