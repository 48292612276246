import styled from 'styled-components';

const Wysiwyg = styled.div`

  a {
    text-decoration: underline;
    @media (hover: hover) {
      text-decoration: none;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    margin-bottom: 25px;
    text-transform: uppercase;
    @media only screen and (min-width: 1024px){
      font-size: 26px;
      line-height: 30px;
    }
  }

  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    padding-top: 18px;
    @media only screen and (min-width: 1024px){
      padding-top: 34px;
    }
  }

  p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 26px;
    @media only screen and (min-width: 1024px){
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 28px;
    }
  }

  ul, 
  ol {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 26px;
    @media only screen and (min-width: 1024px){
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 28px;
    }
  }
 
  &:last-child{
      padding-bottom: 115px;
      @media only screen and (min-width: 1024px){
        padding-bottom: 160px;
    }
  }


`

export default Wysiwyg